import {Box, Icon, Image, Link, Spinner, Text, Tooltip} from "@chakra-ui/react";
import React, {useState} from "react";
import {AiOutlineHeart, AiFillHeart} from "react-icons/all";
import CommentsList from "../components/CommentsList";

const Post = ({id, username, title, content, image, link, type, likes = 0, createdAt, comments}) => {
	/*
		Ideally I'd keep track of posts the user has already liked, but for
		simpility they can like as many times as they like by refreshing
	 */
	const [liked, setLiked] = useState(false);
	const [loadingLike, setLoadingLike] = useState(false);
	const [postLikes, setPostLikes] = useState(likes);

	const handleLikePost = async (event) => {
		event.preventDefault();

		setLoadingLike(true);

		await fetch("https://hiring-api.dantarr.workers.dev/like", {
			method: "PUT",
			body: JSON.stringify({id}),
			headers: {
				"content-type": "application/json"
			}
		});

		setLoadingLike(false);
		setLiked(true);
		setPostLikes(postLikes + 1);
	};

	let tooltipContent;

	if (loadingLike) {
		tooltipContent =
			<span>
				<Spinner />
			</span>;
	} else {
		if (liked) {
			tooltipContent =
				<span>
					<Icon color="darkred" as={AiFillHeart}/>
				</span>
		} else {
			tooltipContent =
				<span onClick={handleLikePost}>
					<Icon as={AiOutlineHeart}/>
				</span>
		}
	}

	return (
		<Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" width="100%">
			<Box p="6">
				<Box d="flex" alignItems="baseline">
					<Box
						color="gray.500"
						fontWeight="semibold"
						letterSpacing="wide"
						fontSize="md"
						ml="2"
					>
						Post by {username}
					</Box>
				</Box>

				<Box
					mt="1"
					fontWeight="semibold"
					as="h4"
					lineHeight="tight"
					isTruncated
				>
					{title}
				</Box>

				<Box>
					{type === "text" ?
						<Text>{content}</Text>
						: ""
					}

					{type === "image" ?
						<Box>
							<Image src={image} margin="auto"/>
						</Box>
						: ""
					}

					{type === "link" ?
						<Link
							href={link}
							target="_blank"
							color="lightblue">
							{link}
						</Link>
						: ""
					}
				</Box>

				<Box mt="4">
					<Tooltip label={`${postLikes} likes`} fontSize="md">
						{tooltipContent}
					</Tooltip>
				</Box>

				<Box d="flex" alignItems="baseline" justifyContent="flex-end">
					<Box
						mt="1"
						fontWeight="light"
						lineHeight="tight"
						fontSize="md"
						color="gray.500"
					>
						{createdAt}
					</Box>
				</Box>

			</Box>

			<CommentsList postId={id} comments={comments}/>
		</Box>
	);
};

export default Post;
