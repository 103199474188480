import React from "react";
import {
	ChakraProvider,
	Box,
	VStack,
	Grid,
	Heading,
	Divider
} from "@chakra-ui/react";
import NewPost from "./components/NewPost";
import PostsList from "./components/PostsList";

function App() {
	return (
		<ChakraProvider>
			<Box textAlign="center" fontSize="xl" backgroundColor="gray.800" color="white">
				<Grid minH="100vh" p={3}>
					<VStack spacing={8}>
						<Heading>
							My Social Media App
						</Heading>

						<NewPost />

						<Divider color="white" width="40%"/>

						<PostsList/>
					</VStack>
				</Grid>
			</Box>
		</ChakraProvider>
	);
}

export default App;
