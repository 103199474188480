import {Box, Text} from "@chakra-ui/react";
import React from "react";

const Comment = ({username, comment, createdAt}) => {
	return (
		<Box borderTopWidth="1px" p="2">
			<Box d="flex" alignItems="baseline">
				<Box
					color="gray.500"
					fontWeight="semibold"
					letterSpacing="wide"
					fontSize="md"
					ml="4"
				>
					Comment by {username}
				</Box>
			</Box>

			<Box>
				<Text fontSize="lg">{comment}</Text>
			</Box>

			<Box d="flex" alignItems="baseline" justifyContent="flex-end">
				<Box
					mt="1"
					fontWeight="light"
					lineHeight="tight"
					fontSize="md"
					color="gray.500"
				>
					{createdAt}
				</Box>
			</Box>
		</Box>
	);
};

export default Comment;
