import Post from "./Post";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Box, Button} from "@chakra-ui/react";
import {RepeatIcon} from "@chakra-ui/icons";

const PostsList = () => {
	const [posts, setPosts] = useState(null);
	const [refreshPostsLoading, setRefreshPostsLoading] = useState(null);

	const fetchPostsAsync = async () => {
		let response = await fetch("https://hiring-api.dantarr.workers.dev/posts");

		return await response.json();
	};

	useEffect(() => {
		fetchPostsAsync().then(posts => {
			setPosts(posts);
		});
	},  []);

	const handleRefreshClick = async () => {
		setRefreshPostsLoading(true);

		let posts = await fetchPostsAsync();
		setPosts(posts);

		setRefreshPostsLoading(false);
	};

	return (
		<>
			<Box>
				<Button
					colorScheme="teal"
					size="md"
					mb="4"
					onClick={handleRefreshClick}
					leftIcon={<RepeatIcon />}
					isLoading={refreshPostsLoading}>
					Refresh Posts
				</Button>
			</Box>

			{posts ? posts.map(post => {
				return (
					<Post
						key={post.id}
						id={post.id}
						title={post.title}
						username={post.username}
						content={post.content}
						image={post.image}
						link={post.link}
						type={post.type}
						likes={post.likes}
						comments={post.comments}
						createdAt={moment(post.createdAt).fromNow()}
					/>
				);
			}) : ""}
		</>
	);
};

export default PostsList;
