import moment from "moment";
import Comment from "./Comment";
import NewComment from "./NewComment";

const CommentsList = ({postId, comments}) => {
	return (
		<>
			{comments ? comments.map(comment => {
				return <Comment
					username={comment.username}
					comment={comment.comment}
					createdAt={moment(comment.createdAt).fromNow()}
				/>
			}) : ""}

			<NewComment postId={postId}/>
		</>
	);
}

export default CommentsList;
