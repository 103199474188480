import {
	Box, Button,
	Input,
	Select,
	Text,
	Textarea
} from "@chakra-ui/react";
import React, {useState} from "react";

const NewPost = () => {
	const [selectedPostType, setSelectedPostType] = useState("text");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(false);

	const onSelectChanged = (event) => {
		setSelectedPostType(event.target.value);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		let {
			username,
			title,
			content,
			image,
			link
		} = event.target;

		let requestPayload = {};
		setSuccess(null);

		if (!username.value) {
			setError("Username cannot be blank");
			return;
		} else
			requestPayload.username = username.value;

		if (!title.value) {
			setError("Title cannot be blank");
			return;
		} else
			requestPayload.title = title.value;

		if (selectedPostType === "text" && !content.value) {
			setError("Content cannot be blank");
			return;
		} else if (selectedPostType === "text")
			requestPayload.content = content.value;

		if (selectedPostType === "image" && !image.value) {
			setError("Image URL cannot be blank");
			return;
		} else if (selectedPostType === "image") {
			try {
				let url = new URL(image.value);

				if (url.protocol !== "http:" && url.protocol !== "https:") {
					setError("Image URL must be a valid HTTP or HTTPS URL");
					return;
				}
			} catch (e) {
				setError("Invalid image URL provided");
				return;
			}

			requestPayload.image = image.value;
		}

		if (selectedPostType === "link" && !link.value) {
			setError("Link cannot be blank");
			return;
		} else if (selectedPostType === "link") {
			try {
				let url = new URL(link.value);

				if (url.protocol !== "http:" && url.protocol !== "https:") {
					setError("Link must be a valid HTTP or HTTPS URL");
					return;
				}
			} catch (e) {
				setError("Invalid link provided");
				return;
			}

			requestPayload.link = link.value;
		}

		requestPayload.type = selectedPostType;
		setError(null);

		try {
			setLoading(true);

			let newPostResponse = await fetch("https://hiring-api.dantarr.workers.dev/posts", {
				method: "POST",
				body: JSON.stringify(requestPayload),
				headers: {
					"content-type": "application/json"
				},
				credentials: "include"
			});

			setLoading(false);

			if (newPostResponse.status === 200)
				setSuccess("Successfully created post. It may take up to a few minutes before your post appears in the list.")
			else
				setError(await newPostResponse.text())
		} catch (err) {
			setLoading(false);
		}
	}

	return (
		<Box width="35%">
			<Text mb="2">
				New Post
			</Text>
			<Text fontSize="md" color="gray.300" mb="4">
				Use the form below to create a new post
			</Text>

			<form onSubmit={handleFormSubmit}>
				<Input
					width="100%"
					placeholder="Username"
					focusBorderColor="white"
					mb="4"
					name="username"
				/>
				<Input
					width="100%"
					placeholder="Title"
					focusBorderColor="white"
					mb="4"
					name="title"
				/>

				<Select focusBorderColor="white" onChange={onSelectChanged} mb="4" defaultValue="text">
					<option value="text" style={{backgroundColor: "#1A202C"}}>
						Plain text
					</option>
					<option value="image" style={{backgroundColor: "#1A202C"}}>
						Image/GIF
					</option>
					<option value="link" style={{backgroundColor: "#1A202C"}}>
						Link
					</option>
				</Select>

				{selectedPostType === "text" ?
					<Textarea placeholder="Content" mb="4" name="content"/>
					: ""
				}

				{selectedPostType === "image" ?
					<Input
						width="100%"
						placeholder="Image URL"
						focusBorderColor="white"
						mb="4"
						name="image"
					/>
					: ""
				}

				{selectedPostType === "link" ?
					<Input
						width="100%"
						placeholder="URL"
						focusBorderColor="white"
						mb="4"
						name="link"
					/>
					: ""
				}

				{error ? <Text color="red" fontSize="lg" mb="3">{error}</Text> : ""}
				{success ? <Text color="green" fontSize="lg" mb="3">{success}</Text> : ""}

				<Box>
					<Button
						colorScheme="teal"
						size="md"
						type="submit"
						isLoading={loading}>
						Submit
					</Button>
				</Box>
			</form>
		</Box>
	);
}

export default NewPost;
