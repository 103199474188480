import {Box, Button, Input, Text} from "@chakra-ui/react";
import React, {useState} from "react";

const NewComment = ({postId}) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		let {
			username,
			comment
		} = event.target;

		let requestPayload = {
			id: postId
		};

		if (!username.value) {
			setError("Username cannot be blank");
			return;
		} else
			requestPayload.username = username.value;

		if (!comment.value) {
			setError("Comment cannot be blank");
			return;
		} else
			requestPayload.comment = comment.value;

		setError(null);

		try {
			setLoading(true);

			let newCommentResponse = await fetch("https://hiring-api.dantarr.workers.dev/comment", {
				method: "POST",
				body: JSON.stringify(requestPayload),
				headers: {
					"content-type": "application/json"
				},
				credentials: "include"
			});

			setLoading(false);

			if (newCommentResponse.status === 200)
				setSuccess("Successfully created comment. It may take up to a few minutes before your comment appears on the post.")
			else
				setError(await newCommentResponse.text());
		} catch (err) {

		}
	};

	return (
		<Box borderTopWidth="1px" p="2">
			<Text mb="1">
				New Comment
			</Text>
			<Text fontSize="md" color="gray.300" mb="3">
				Use the form below to create a new comment on this post
			</Text>

			<form onSubmit={handleFormSubmit}>
				<Input
					width="100%"
					placeholder="Username"
					focusBorderColor="white"
					mb="4"
					name="username"
				/>
				<Input
					width="100%"
					placeholder="Comment"
					focusBorderColor="white"
					mb="4"
					name="comment"
				/>

				{error ? <Text color="red" fontSize="lg" mb="3">{error}</Text> : ""}
				{success ? <Text color="green" fontSize="lg" mb="3">{success}</Text> : ""}

				<Box>
					<Button
						colorScheme="teal"
						size="md"
						type="submit"
						isLoading={loading}>
						Submit
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default NewComment;
